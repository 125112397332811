
import PageNavbar from '@/components/page_navbar'
import LoadingSpinner from '@/components/loading_spinner'
import { formatted_datetime, number_to_currency } from '@/lib/helpers'
import OrderShippingStatusPill from '@/components/order_shipping_status_pill'

export default {
  components: { PageNavbar, LoadingSpinner, OrderShippingStatusPill }

  data: -> {
    next_page: 1
  },

  props: [],

  computed: {
    loading: -> @$store.state.orders.loading
    orders: -> @$store.state.orders.history
  }

  methods: {
    formatted_datetime: (datetime, with_time) ->
      if with_time then formatted_datetime(datetime, 'dd LLL y T') else formatted_datetime(datetime, 'dd LLL y')

    number_to_currency: (cents) ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: cents })

    formatted_text: (string) ->
      return unless !!string
      chars = string.split('')
      chars.splice(0, 1, chars[0].toUpperCase())
      chars.join('').split('_').join(' ')

    dispatch_method: (requires_shipping) ->
      if requires_shipping then 'delivery' else 'collection'

    order_status_pill: (shipping_status) ->
      classname = 'r---pill--'
      classname += 'green' if shipping_status in ['collected', 'delivered']
      classname += 'red' if shipping_status in ['stock_short', 'ready_for_collection', 'pending_payment']
      classname += 'yellow' if shipping_status in ['pending', 'preparing', 'ready_for_delivery', 'out_for_delivery']
      classname

    on_scroll: (e) ->
      return unless e.target.scrollHeight - e.target.scrollTop == e.target.clientHeight
      return if @$store.state.orders.loading
      return if @next_page == null
      @$store.dispatch('orders/fetch_history', @next_page).then((next_page) => @next_page = next_page)
  },

  created: ->
    @$store.dispatch('orders/fetch_history', @next_page)
    .then((next_page) => @next_page = next_page)

  destroyed: ->
    @$store.commit('orders/flush_history')
}
